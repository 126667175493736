import { render, staticRenderFns } from "./healthResultTip.vue?vue&type=template&id=3e0ba106&scoped=true"
import script from "./healthResultTip.vue?vue&type=script&lang=js"
export * from "./healthResultTip.vue?vue&type=script&lang=js"
import style0 from "./healthResultTip.vue?vue&type=style&index=0&id=3e0ba106&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3e0ba106",
  null
  
)

export default component.exports